import * as React from "react"
import "./someofhighlights.scss"
import { createMarkup } from "../../../utils"

export default function SomeOfTheHighlights({data,translation}) {
  return (
    <>
      <section className="some-of-highlights">
        <div className="container">
          <div className="big-wrapper">
            <div className="box-wrapper">
              <div className="text">
                {translation}
              </div>
            </div>
            <ul className="combo-highlights">
              <li>
                <div className="col-group">
                  <div className="col-dt-6 left-col"></div>
                  <div className="col-dt-6 right-col">
                    <div className="wrapper">
                      <div className="img-wrapper">
                        <img src={data?.gallery?.firstImage?.localFile?.publicURL} alt={data?.gallery?.firstImageCaption} />
                      </div>
                      <div className="title" dangerouslySetInnerHTML={createMarkup(data?.gallery?.firstImageCaption)}></div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="col-group">
                  <div className="col-dt-6 left-col">
                    <div className="wrapper">
                      <div className="img-wrapper">
                        <img src={data?.gallery?.secondImage?.localFile?.publicURL} alt={data?.gallery?.secondImageCaption} />
                      </div>
                      <div className="title" dangerouslySetInnerHTML={createMarkup(data?.gallery?.secondImageCaption)}></div>
                    </div>
                  </div>
                  <div className="col-dt-6 right-col"></div>
                </div>
              </li>
              <li>
                <div className="col-group">
                  <div className="col-dt-6 left-col"></div>
                  <div className="col-dt-6 right-col">
                    <div className="wrapper">
                      <div className="img-wrapper">
                        <img src={data?.gallery?.thirdImage?.localFile?.publicURL} alt={data?.gallery?.thirdImageCaption} />
                      </div>
                      <div className="title" dangerouslySetInnerHTML={createMarkup(data?.gallery?.thirdImageCaption)}></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}
