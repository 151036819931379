import * as React from "react"
import { useContext } from "react"
import "./projectInNumber.scss"
import { useStaticQuery, graphql } from "gatsby"
import { string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function ProjectInNumber(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const result = useStaticQuery(graphql`
    {
      stringTranslations {
        project_ar:translateString(language: AR, string: "PROJECT")
        project_en:translateString(language: EN, string: "PROJECT")
        in_num_ar:translateString(language: AR, string: "IN NUMBER")
        in_num_en:translateString(language: EN, string: "IN NUMBER")
      }
    }
  `)
  
  let { data } = props
  if (data === "") {
    data = result.wpPage.homepageFields.projectInNumber
  }
  return (
    <>
      <section className="project-in-number ">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-6">
              <div className="img-wrapper img-wrapper1">
                <img data-aos="fade-right" data-aos-duration="800"
                            data-aos-easing="ease-in-sine"
                  className="desktop-show"
                  src="/images/project-in-number/Projectinnumbers/bike-svg.svg"
                  alt="Bike"
                />
                <img
                  className="mobile-show"
                  src="/images/project-in-number/Projectinnumbers/bike-mobile-svg.png"
                  alt="Bike Mobile"
                />
              </div>
            </div>
          </div>

          <div className="second-wrapper">
            <div className="col-group">
              <div
                className="col-dt-4"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-easing="ease-in-sine"
              >
                <div className="title">{data?.title1 ? data?.title1 : string_translation(result.stringTranslations,"project", locale)}</div>
                <div className="big-title h2">{data?.title2 ? data?.title2 : string_translation(result.stringTranslations,"in_num", locale)}</div>
                <hr className="title-hr" />
                <div className="desc">
                  {data?.text ? data?.text : data?.ipnText}
                </div>
              </div>
              <div className="col-dt-8 img-number">
                <div className="img-wrapper">
                  <img
                    className="desktop-show"
                    data-aos="fade-left" data-aos-duration="800"
                    data-aos-easing="ease-in-sine"
                    src={
                      data.desktopImage?.localFile?.publicURL
                        ? data.desktopImage?.localFile?.publicURL
                        : data.ipnDesktopImage?.localFile?.publicURL
                    }
                    alt={data?.title1 ? data?.title1 : string_translation(result.stringTranslations,"project", locale)}
                  />
                  <img
                    className="mobile-show"
                    src={
                      data.mobileImage?.localFile?.publicURL
                        ? data.mobileImage?.localFile?.publicURL
                        : data.ipnMobileImage?.localFile?.publicURL
                    }
                    alt={data?.title1 ? data?.title1 : string_translation(result.stringTranslations,"project", locale)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
