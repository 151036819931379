import * as React from "react"
import { useContext } from "react"
import { string_translation } from "../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import FirstDistrictZone from "../components/FirstDistrictZone"
import DescriptionSection from "../components/DescriptionSection"
import ProjectInNumber from "../components/ProjectInNumber"
import SomeOfTheHighlights from "../components/SomeOfTheHighlights"



export default function DistrictDetail({pageContext, location}) {
  let showHighlights = true

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let mainUrl= location.state?.parenturl
  if(!mainUrl){mainUrl = "/"+locale}
  
  let seo = pageContext?.node?.seo
  let translatedURI = pageContext?.node?.translations[0]?.uri

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      title_ar:translateString(language: AR, string: "PROJECT")
      title_en:translateString(language: EN, string: "PROJECT")
      subtitle_ar:translateString(language: AR, string: "IN NUMBER")
      subtitle_en:translateString(language: EN, string: "IN NUMBER")
      highlights_ar:translateString(language: AR, string: "DISTRICT HIGHLIGHTS TITLE")
      highlights_en:translateString(language: EN, string: "DISTRICT HIGHLIGHTS TITLE")
    }
  }`)

  if(
    (pageContext.node.districtOptions.gallery.firstImage == null) || 
    (pageContext.node.districtOptions.gallery.secondImage == null) || 
    (pageContext.node.districtOptions.gallery.thirdImage == null)) {
      showHighlights = false
  }
  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
      <WhoWeAre
          title={pageContext?.node?.pageSubtitle?.subtitle}
          name={pageContext?.node?.title}
          description={pageContext?.node?.pageDescription?.pageDescription}
        />
      
        <FirstDistrictZone data={pageContext.node.districtOptions} parentUrl={mainUrl}/>
        <DescriptionSection data={pageContext.node}/>
        <ProjectInNumber
            title1={pageContext.node.districtOptions.projectInNumber}
            title2={pageContext.node.districtOptions.projectInNumber}
            data={pageContext.node.districtOptions.projectInNumber}
          />
        {showHighlights && 
        <SomeOfTheHighlights data={pageContext.node.districtOptions} translation={string_translation(stringTranslations,"highlights", locale)}/>}
      </Layout>
    </>
  )
}
