import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import "./firstdistrictzone.scss"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {useStaticQuery, graphql} from "gatsby"
import { string_translation } from "../../../utils"

export default function FirstDistrictZone({ data , parentUrl }) {

  const {
    locale, // ar
    locales, // ["en", "ar"]
    originalUrl, // "/about-us"
    translateUrl, // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const {stringTranslations,backBtn_en,backBtn_ar} = useStaticQuery(graphql`query {
    stringTranslations {
      backDistrict_ar:translateString(language: AR, string: "BACK TO DISTRICTS")
      backDistrict_en:translateString(language: EN, string: "BACK TO DISTRICTS")
      
    }
    backBtn_en:wpPage(
      template: {templateName: {eq: "Our-districts"}}
      language: {locale: {eq: "en_US"}}
    ) {
      uri
    }
    backBtn_ar:wpPage(
      template: {templateName: {eq: "Our-districts"}}
      language: {locale: {eq: "ar"}}
    ) {
      uri
    }
  }`)

  return (
    <>
      <section className="landing-banner">
        <div className="container">
          <Link
            to={locale == "en" ? backBtn_en.uri : backBtn_ar.uri}
            className="back-wrapper"
          >
            <div className="back-img-wrapper">
              <img src="/images/district/arrow.svg" alt="Arrow" />
            </div>
            <div className="back-text">
              {" "}
              {string_translation(stringTranslations, "backDistrict", locale)}
            </div>
          </Link>
          <div className="img-wrapper">
            <img
              className="desktop-show"
              src={data?.heroImageDesktop?.localFile?.publicURL}
              alt={data?.projectInNumber?.title1}
            />
            <img
              className="mobile-show"
              src={data?.heroImageMobile?.localFile?.publicURL}
              alt={data?.projectInNumber?.title1}
            />
          </div>
        </div>
      </section>
    </>
  )
}
