import React from "react"
import "./descriptionsection.scss"
import { createMarkup } from "../../../utils"

export default function DescriptionSection({data}) {

  return (
    <>
      <section className="desc-section">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-6">
              <div className="img-wrapper-big">
                <div className="img-wrapper">
                  <img src={data.districtOptions?.secondaryImage?.localFile?.publicURL} alt={data.districtOptions?.projectInNumber?.title1} />
                  <img
                    className="logo desktop-show"
                    src="/images/district/logo.svg"
                    alt="District"
                  />
                  <img
                    className="logo mobile-show"
                    src="/images/district/logo-mobile.svg"
                    alt="District"
                  />
                </div>
              </div>
            </div>
            <div className="col-dt-6 col-right">
              <div className="text-wrapper" dangerouslySetInnerHTML={createMarkup(data.content)}>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
